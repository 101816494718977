<template>
    <div>
        <div class="padding">
            <el-page-header @back="goBack" :content="$t('我的成绩')">
            </el-page-header>
        </div>
        <el-card class="margin-t" style="max-width: 1000px; margin:0 auto;">

            <div class="padding margin-t">
                <div class="flex flex-h-center flex-v-center" style="position: relative;">
                    <div class="bold center lt">{{ $t('录入成绩') }}</div>
                    <el-button style="position: absolute;right:0;" type="primary" round icon="el-icon-edit"
                        @click="$refs.goalDialog.show()">
                        {{ $t('学习目标') }}
                    </el-button>
                </div>

                <div class="flex margin-t">
                    <el-form :inline="true" ref="form" label-position="top" :model="form">
                        <div class="flex">
                            <div class="flex1">
                                <el-form-item label="时间"
                                    :rules="[{ required: true, message: '请选择日期', trigger: 'blur' }]" prop="day">
                                    <el-date-picker value-format="yyyy-MM-dd" style="width: inherit;" v-model="form.day"
                                        type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="口语" prop="s" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.s" placeholder="口语分数"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="写作" prop="w" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.w" placeholder="写作分数"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item label="阅读" prop="r" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.r" placeholder="阅读分数"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item label="听力" prop="l" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.l" placeholder="听力分数"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="总分" prop="t" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input :max="90" :min="10" v-model="form.t" placeholder="总分分数"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex flex-h-end">
                                <el-form-item label="⁡ ">
                                    <el-button type="primary" @click="submit">提交</el-button>
                                </el-form-item>
                            </div>

                        </div>


                    </el-form>
                </div>

                <el-card>
                    <div class="bold center" style="letter-spacing: 10px;">{{ $t('历史成绩')
                        }}</div>
                    <div class="flex flex-h-center">
                        <div class="flex1" id="chart" style="width:100%;height: 400px;margin: 20px 0"></div>
                    </div>
                </el-card>
                <el-divider></el-divider>
                <div class="bold center lt">{{ $t('历史记录') }}</div>
                <div class="flex margin-lt">
                    <el-table :data="tableData">
                        <el-table-column prop="day" :label="$t('时间')"></el-table-column>
                        <el-table-column prop="s" :label="$t('口语')"></el-table-column>
                        <el-table-column prop="w" :label="$t('写作')"></el-table-column>
                        <el-table-column prop="r" :label="$t('阅读')"></el-table-column>
                        <el-table-column prop="l" :label="$t('听力')"></el-table-column>
                        <el-table-column prop="t" :label="$t('总分')"></el-table-column>

                        <el-table-column>
                            <template slot-scope="scope">
                                <el-button type="primary" @click="$refs.transcriptDialog.show(scope.row)" v-if="scope.row.items"
                                    size="mini" round>{{ $t('查看')
                                    }}</el-button>
                                <el-button type="primary"  @click="transcript(scope.row)" v-else size="mini" round>{{ $t('成绩单分析') }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-card>
        <goalDialog ref="goalDialog"></goalDialog>
        <transcript-dialog ref="transcriptDialog"></transcript-dialog>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    DatasetComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
import goalDialog from '../../components/GoalDialog.vue';
import transcriptDialog from '../../components/TranscriptDialog.vue';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    MarkLineComponent,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent
]);
export default {
    components: {
        goalDialog,transcriptDialog
    },
    data() {
        return {
            checkInput: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('分数不能为空'));
                }
                setTimeout(() => {
                    if (isNaN(Number(value)) || value < 10 || value > 90) {
                        callback(new Error('输入10-90的整数'));
                    } else {
                        callback();
                    }
                });
            },
            form: { s: '', w: '', r: '', l: '', t: '', day: '' },
            tableData: [],
            datas: [],
            chart: null,
            chartOpiton: {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [this.$t('口语'), this.$t('写作'), this.$t('阅读'), this.$t('听力'), this.$t('总分')]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value',
                    max: 90,
                    interval: 15
                },
                series: [
                    {
                        name: this.$t('口语'),
                        type: 'line',
                        data: [],
                    },
                    {
                        name: this.$t('写作'),
                        type: 'line',
                        data: [],
                    },
                    {
                        name: this.$t('阅读'),
                        type: 'line',
                        data: [],
                    },
                    {
                        name: this.$t('听力'),
                        type: 'line',
                        data: [],
                    },
                    {
                        name: this.$t('总分'),
                        type: 'line',
                        data: [],
                        markLine: {
                            label: {
                                position: 'middle',
                                formatter: '{b}: {c}'
                            },
                            data: [
                                { type: 'value', yAxis: 50, name: '目标成绩' },
                            ]
                        }
                    },
                ],
            }
        };
    },

    mounted() {
        this.chart = echarts.init(document.getElementById('chart'));
        this.query_chart();
    },

    computed: {
        records: function () {
            return this.$store.state.records
        },
    },

    methods: {
        transcript(item) {
            this.$router.push({
                path: '/main/transcript',
                query: { day: item.day, s: item.s, w: item.w, r: item.r, l: item.l, t: item.t }
            })
        },
        goBack() {
            this.$router.back();
        },
        toPlan() {
            this.$router.replace('/main/plan')
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const keys = ['s', 'w', 'r', 'l', 't']
                    for (let i = 0; i < keys.length; i++) {
                        if (isNaN(Number(this.form[keys[i]]) || this.form[keys[i]] < 0 || this.form(keys[i]) > 90)) {
                            this.$message.warn(this.$t('分数在0到90之间'))
                            return
                        }
                    }

                    this.$http.post("/api/exam/score/save", this.form).then(() => {
                        this.form = { s: '', w: '', r: '', l: '', t: '', day: '' }
                        this.$message.success(this.$t('保存成功'))
                        this.query_chart()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        query_chart() {
            this.$http.post("/api/exam/score/list").then((list) => {
                this.tableData = list
                const names = [this.$t('口语'), this.$t('写作'), this.$t('阅读'), this.$t('听力'), this.$t('总分')]
                if (list.length > 0) {
                    let name = null
                    for (let j = 0; j < 5; j++) {
                        name = names[j]
                        this.chartOpiton.legend.data[j] = name
                        this.chartOpiton.series[j].name = name
                    }
                }
                const keys = ['s', 'w', 'r', 'l', 't']
                for (let i = 0; i < keys.length; i++) {

                    this.chartOpiton.series[i].data = list.map(val => val[keys[i]])
                }
                this.chartOpiton.xAxis.data = list.map(val => val['day'])
                this.chartOpiton.series[4].markLine.data[0].yAxis = list[0].exam_data[4]
                console.log(this.chartOpiton)
                this.chart.setOption(this.chartOpiton);


            })
        },
    },
};
</script>
<style scoped>
.border {
    border-color: #E6A23C;
}

.document>>>.el-card__header {
    padding: 10px 20px;
}

.mi {
    height: 50px;
    line-height: 50px;
}


.document>>>.el-divider__text {
    color: #aaa;
}
</style>
